import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { MAT_DATE_LOCALE, MatChipsModule, MatSlideToggleModule } from '@angular/material';
import { NgDatepickerModule } from 'ng2-datepicker';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { NgxDateRangePickerModule } from 'ngx-daterangepicker';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatButtonModule, MatCheckboxModule, MatIconModule, MatCardModule, MatFormFieldModule, MatGridListModule } from '@angular/material';
import { MatDividerModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatInputModule } from '@angular/material';
import { MatSelectModule, MatTableModule, MatPaginatorModule, MatDialogModule,
MatTooltipModule, MatTabsModule, MatToolbarModule, MatStepperModule, MatProgressSpinnerModule } from '@angular/material';
import { DialogNewValueDialog, DialogResponseDialog} from './pages/maintenancefuel/maintenancefuel.component';
import 'hammerjs';
import * as $ from 'jquery';
import { MaintenancetruckComponent, DialogResponseDialogTruck,
  DialogNewValueDialogTruck } from './pages/maintenancetruck/maintenancetruck.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import {ComponentsModule} from './components/components.module';
import { WhiteLayoutComponent } from './layout/white-layout/white-layout.component';
import { DialogResponseDialogDriver,
  DialogNewValueDialogDriver } from './pages/driver/driver.component';

@NgModule({
  declarations: [
    AppComponent,
    DialogResponseDialogDriver,
    DialogNewValueDialogDriver,
    DialogResponseDialogTruck,
    DialogNewValueDialogTruck,
    DialogNewValueDialog,
    DialogResponseDialog,
    MaintenancetruckComponent,
    AdminLayoutComponent,
    WhiteLayoutComponent,
  ],
  imports: [
    AppRoutingModule,
    ComponentsModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    NgxMatDrpModule,
    FormsModule,
    NgxDateRangePickerModule,
    HttpClientModule,
    MatStepperModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    MatTableModule,
    SelectDropDownModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTabsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule.forRoot(),
    MatCardModule,
    MatFormFieldModule,
    NgDatepickerModule,
    MatGridListModule,
    MatDividerModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule
  ],
  entryComponents: [DialogNewValueDialog, DialogResponseDialog, DialogResponseDialogTruck,
    DialogNewValueDialogTruck, DialogResponseDialogDriver,
    DialogNewValueDialogDriver],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
  bootstrap: [AppComponent]
})

export class AppModule { }
