import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'DASHBOARD',  icon: 'typcn-chart-pie-outline', class: '' },
  { path: '/reportes_especiales', title: 'REPORTES',  icon:'typcn-export-outline', class: '' },
  { path: '/reportes_programados', title: 'REPORTES PROGRAMADOS',  icon:'typcn-time', class: '' },
  { path: '/registro_combustible', title: 'MANTENIMIENTO DE COMBUSTIBLE',  icon:'typcn-spanner-outline', class: '' },
  { path: '/gestion_rutas', title: 'GESTIÓN DE RUTAS',  icon:'typcn-directions', class: '' },
  { path: '/agenda_choferes', title: 'AGENDA DE CHOFERES',  icon:'typcn-contacts', class: '' },
  { path: '/convoy', title: 'CONVOY',  icon:'typcn-location', class: '' },
  { path: '/notificaciones', title: 'NOTIFICACIONES Y ALARMAS',  icon: 'typcn-bell', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  expandflag = false;
  public listTitles: any[];
  user: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    // const enc = localStorage.getItem('enc');
    // const result = (atob(enc)) || 'user|Usuario Orbitec';
    // const array = result.split('|');
    // this.user = array[1];
  }
  expandMenu () {
    this.expandflag = true;
  }

  collapseMenu () {
    this.expandflag = false;
  }
}
