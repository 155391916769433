import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {localhttp} from '../../environments/localenvironment';
import {GlobalService} from './global.service';

interface DataList {
  obj: Object;
}

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private http: HttpClient, private globalService: GlobalService) {
  }

  getList() {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.get<DataList>(localhttp.url + 'driver/get_driver?enc=' + enc,
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.get<DataList>('https://apigts.orbitec.pe/api_gts/data/driver/get_driver?enc=' + enc,
        {headers: headers, withCredentials: true});
    }
  }

  getVehicleList() {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.get<DataList>(localhttp.url + 'driver/get_plates?enc=' + enc,
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.get<DataList>('https://apigts.orbitec.pe/api_gts/data/driver/get_plates?enc=' + enc,
        {headers: headers, withCredentials: true});
    }
  }

  saveUser(data) {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    // headers.set('X-Requested-With', 'XMLHttpRequest');
    data.enc = enc;
    let splitdate = null;
    if (data.birthdate !== null && data.birthdate !== undefined) {
      const fecha = data.birthdate.toString();
      if (fecha.indexOf('-') !== -1) {
        splitdate = data.birthdate.toString().split('-');
        data.birthdate = splitdate[2] + '/' + splitdate[1] + '/' + splitdate[0];
      }
    }
    if (data.license !== null && data.license !== undefined) {
      const fechaLicencia = data.license.toString();
      if (fechaLicencia.indexOf('-') !== -1) {
        splitdate = data.license.toString().split('-');
        data.license = splitdate[2] + '/' + splitdate[1] + '/' + splitdate[0];
      }
    }
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.post(localhttp.url + 'driver/get_driver', JSON.stringify(data), {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.post('https://apigts.orbitec.pe/api_gts/data/driver/get_driver', JSON.stringify(data),
        {headers: headers, withCredentials: true});
    }
  }

  saveEditUser(data) {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    // headers.set('X-Requested-With', 'XMLHttpRequest');
    data.enc = enc;
    let splitdate = null;
    if (data.birthdate !== null && data.birthdate !== undefined) {
      const fecha = data.birthdate.toString();
      if (fecha.indexOf('-') !== -1) {
        splitdate = data.birthdate.toString().split('-');
        data.birthdate = splitdate[2] + '/' + splitdate[1] + '/' + splitdate[0];
      }
    }
    if (data.license !== null && data.license !== undefined) {
      const fechaLicencia = data.license.toString();
      if (fechaLicencia.indexOf('-') !== -1) {
        splitdate = data.license.toString().split('-');
        data.license = splitdate[2] + '/' + splitdate[1] + '/' + splitdate[0];
      }
    }
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.put(localhttp.url + 'driver/get_driver', JSON.stringify(data), {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.put('https://apigts.orbitec.pe/api_gts/data/driver/get_driver', JSON.stringify(data),
        {headers: headers, withCredentials: true});
    }
  }

  deleteItem(data) {
    let headers = new HttpHeaders();
    // headers.set('X-Requested-With', 'XMLHttpRequest');
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.delete(localhttp.url + 'driver/get_driver?driverID=' + data,
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.delete('https://apigts.orbitec.pe/api_gts/data/driver/get_driver?driverID=' + data,
        {headers: headers, withCredentials: true});
    }
  }

  sendEmailData(data) {
    let headers = new HttpHeaders();
    // headers.set('X-Requested-With', 'XMLHttpRequest');
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.post(localhttp.url + 'save_report_data', data, {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.post('https://apigts.orbitec.pe/api_gts/data/save_report_data', data, {headers: headers, withCredentials: true});
    }
  }

  importData(data) {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('enc', enc);
    formData.append('file', data.file, data.file.name);

    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.post(localhttp.url + 'driver/import_driver', formData, {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.post('https://apigts.orbitec.pe/api_gts/data/driver/get_driver', formData,
        {headers: headers, withCredentials: true});
    }
  }

}
