import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatPaginator, MatTableDataSource} from '@angular/material';
import {MaintenancefuelService} from '../../services/maintenancefuel.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatPaginatorIntl} from '@angular/material';

/**
 * @title Registro de componentes
 */
export interface DialogData {
  dateInit: Date;
  maxDate: Date;
  dateTime: Date;
  price: number;
  showPrice: boolean;
  showDate: boolean;
  showDelete: boolean;
  title: string;
  command: string;
}

export interface PeriodicElement {
  date: string;
  price: number;
}

export class MatPaginatorIntlCro extends MatPaginatorIntl {
  firstPageLabel = 'Primera página';
  itemsPerPageLabel = 'Nº de filas';
  nextPageLabel = 'Próxima página';
  previousPageLabel = 'Anterior página';
  lastPageLabel = 'Última página';
}

const ELEMENT_DATA: PeriodicElement[] = [{date: 'Sin datos', price: null}];

@Component({
  selector: 'app-maintenancefuel',
  templateUrl: './maintenancefuel.component.html',
  styleUrls: ['./maintenancefuel.component.scss'],
  providers: [
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro},
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class MaintenancefuelComponent implements OnInit {
  animal: string;
  name: string;
  dateInit: Date;
  maxDate: Date;
  element: any;
  dateTime: Date;
  price: number;
  msgError: string;
  mensaje: {};
  displayedColumns: string[] = ['date', 'price', 'actions'];
  list = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private maintenancefuelService: MaintenancefuelService,
              private adapter: DateAdapter<any>, public dialog: MatDialog) {
  }

  openDialog(value, item): void {
    let dataModal = {};
    switch (value) {
      case 1:
        dataModal = {
          dateTime: this.dateTime, price: this.price, showPrice: true, showDelete: false,
          maxDate: this.maxDate, showDate: true, title: 'Nuevo Registro', command: 'Guardar'
        };
        break;
      case 2:
        dataModal = {
          dateTime: item.date, price: item.price, showPrice: true, showDelete: false,
          maxDate: this.maxDate, showDate: false, title: 'Editar Registro', command: 'Guardar'
        };
        break;
      case 3:
        dataModal = {
          dateTime: item.date, price: item.price, showDelete: true,
          maxDate: this.maxDate, showDate: false, showPrice: false,
          title: 'Eliminar Registro', command: 'Sí, Eliminar'
        };
        break;
    }
    const dialogRef = this.dialog.open(DialogNewValueDialog, {
      width: '350px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      const json = {
        'price': result.price,
        'date': result.dateTime
      };
      switch (value) {
        case 1:
          this.maintenancefuelService.createElement(json)
            .subscribe(dataResponse => {
              console.log('get data ok', dataResponse);
              if (dataResponse['status'] === 'ERROR') {
                this.dialog.open(DialogResponseDialog, {
                  width: '350px',
                  data: dataResponse['msg']
                }).afterClosed().subscribe(resultClose => {
                  console.log('ok', resultClose);
                });
              } else if (dataResponse['status'] === 'OK') {
                this.msgError = null;
                this.maintenancefuelService.getList()
                  .subscribe(data => {
                    console.log('get data ok', data);
                    this.mensaje = data;
                    const dataTable = data['data'];
                    this.list = new MatTableDataSource<PeriodicElement>(dataTable);
                    this.list.paginator = this.paginator;
                  });
              }
            });
          break;
        case 2:
          this.maintenancefuelService.editElement(json)
            .subscribe(dataResponse => {
              console.log('get data ok', dataResponse);
              if (dataResponse['status'] === 'ERROR') {
                this.dialog.open(DialogResponseDialog, {
                  width: '350px',
                  data: dataResponse['msg']
                }).afterClosed().subscribe(resultClose => {
                  console.log('ok', resultClose);
                });
              } else if (dataResponse['status'] === 'OK') {
                this.msgError = null;
                this.maintenancefuelService.getList()
                  .subscribe(data => {
                    console.log('get data ok', data);
                    this.mensaje = data;
                    const dataTable = data['data'];
                    this.list = new MatTableDataSource<PeriodicElement>(dataTable);
                    this.list.paginator = this.paginator;
                  });
              }
            });
          break;
        case 3:
          this.maintenancefuelService.deleteElement(json)
            .subscribe(dataResponse => {
              console.log('get data ok', dataResponse);
              if (dataResponse['status'] === 'ERROR') {
                this.dialog.open(DialogResponseDialog, {
                  width: '350px',
                  data: dataResponse['msg']
                }).afterClosed().subscribe(resultClose => {
                  console.log('ok', resultClose);
                });
              } else if (dataResponse['status'] === 'OK') {
                this.maintenancefuelService.getList()
                  .subscribe(data => {
                    console.log('get data ok', data);
                    if (data['status'] === 'OK') {
                      this.mensaje = data;
                      const dataTable = data['data'];
                      this.msgError = null;
                      this.list = new MatTableDataSource<PeriodicElement>(dataTable);
                      this.list.paginator = this.paginator;
                    } else if (data['status'] === 'ERROR') {
                      this.msgError = data['msg'];
                      this.list = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
                    }
                  });
              }
            });
          break;
      }

    });
  }

  ngOnInit() {
    // variables init
    this.dateInit = new Date();
    this.maxDate = new Date();
    this.dateTime = new Date();
    this.price = 0;
    this.msgError = null;
    // ******************
    this.maintenancefuelService.getList()
      .subscribe(data => {
        console.log('get data ok', data);
        if (data['status'] === 'OK') {
          this.mensaje = data;
          const dataTable = data['data'];
          this.msgError = null;
          this.list = new MatTableDataSource<PeriodicElement>(dataTable);
          this.list.paginator = this.paginator;
        } else if (data['status'] === 'ERROR') {
          this.msgError = data['msg'];
        }
      });
  }
}


@Component({
  selector: `dialog-new-value-dialog`,
  templateUrl: 'dialog-new-value-dialog.html',
})
export class DialogNewValueDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogNewValueDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: `dialog-response-dialog`,
  templateUrl: 'dialog-response-dialog.html',
})
export class DialogResponseDialog {
  constructor(
    public dialogRef2: MatDialogRef<DialogResponseDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef2.close();
  }
}
