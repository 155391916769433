import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatPaginator, MatTableDataSource} from '@angular/material';
import { MaintenancefuelService } from '../../services/maintenancefuel.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatPaginatorIntl} from '@angular/material';

/**
 * @title Registro de componentes
 */
export interface DialogData {
  vehicle: string;
  km_tope: number;
  fecha: Date;
  showPrice: boolean;
  showDelete: boolean;
  showDate: boolean;
  title: string;
  command: string;
}
export interface PeriodicElement {
  vehicle: string;
  km_tope: number;
  fecha: number;
}

export class MatPaginatorIntlCro extends MatPaginatorIntl {
  firstPageLabel = 'Primera página';
  itemsPerPageLabel = 'Nº de filas';
  nextPageLabel     = 'Próxima página';
  previousPageLabel = 'Anterior página';
  lastPageLabel = 'Última página';
}

const ELEMENT_DATA: PeriodicElement[] = [ { vehicle: 'Sin datos', 'km_tope': null, 'fecha': null } ];

@Component({
  selector: 'app-maintenancetruck',
  templateUrl: './maintenancetruck.component.html',
  styleUrls: ['./maintenancetruck.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro},
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class MaintenancetruckComponent implements OnInit {
  name: string;
  title: string;
  km_tope: number;
  element: any;
  fecha: Date;
  vehicle: string;
  msgError: string;
  mensaje: {};
  displayedColumns: string[] = ['vehicle', 'km_tope', 'fecha'];
  list = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private maintenancefuelService: MaintenancefuelService,
              private adapter: DateAdapter<any>, public dialog: MatDialog) {}
  openDialog(value, item): void {
    let dataModal = {
      vehicle: '',
      km_tope: 0,
      fecha: new Date(),
      showPrice: false,
      showDelete: false,
      showDate: false,
      title: '',
      command: ''
    };
    switch (value) {
      case 1:
        dataModal = { vehicle: this.vehicle, km_tope: this.km_tope, fecha: this.fecha,
          showPrice: true, showDelete: false,
          showDate: true, title: 'Nuevo Registro', command: 'Guardar' };
        break;
      case 2:
        dataModal = { vehicle: item.date, km_tope: item.km_tope, fecha: item.fecha, showPrice: true,
          showDelete: false,
          showDate: false, title: 'Editar Registro', command: 'Guardar' };
        break;
      case 3:
        dataModal = { vehicle: item.date, km_tope: item.km_tope, fecha: item.fecha, showDelete: true,
          showDate: false, showPrice: false,
          title: 'Eliminar Registro', command: 'Sí, Eliminar' };
        break;
    }
    const dialogRef = this.dialog.open(DialogNewValueDialogTruck, {
      width: '350px',
      data: dataModal
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // const json = {
      //   'price': result.price,
      //   'date': result.dateTime
      // };
      // switch (value) {
      //   case 1:
      //     this.maintenancefuelService.createElement(json)
      //       .subscribe(dataResponse => {
      //         console.log('get data ok', dataResponse);
      //         if (dataResponse['status'] === 'ERROR') {
      //           this.dialog.open(DialogResponseDialog, {
      //             width: '350px',
      //             data: dataResponse['msg']
      //           }).afterClosed().subscribe(resultClose => {
      //             console.log('ok', resultClose);
      //           });
      //         } else if (dataResponse['status'] === 'OK') {
      //           this.msgError = null;
      //           this.maintenancefuelService.getList()
      //             .subscribe(data => {
      //               console.log('get data ok', data);
      //               this.mensaje = data;
      //               const dataTable = data['data'];
      //               this.list = new MatTableDataSource<PeriodicElement>(dataTable);
      //               this.list.paginator = this.paginator;
      //             });
      //         }
      //       });
      //     break;
      //   case 2:
      //     this.maintenancefuelService.editElement(json)
      //       .subscribe(dataResponse => {
      //         console.log('get data ok', dataResponse);
      //         if (dataResponse['status'] === 'ERROR') {
      //           this.dialog.open(DialogResponseDialog, {
      //             width: '350px',
      //             data: dataResponse['msg']
      //           }).afterClosed().subscribe(resultClose => {
      //             console.log('ok', resultClose);
      //           });
      //         } else if (dataResponse['status'] === 'OK') {
      //           this.msgError = null;
      //           this.maintenancefuelService.getList()
      //             .subscribe(data => {
      //               console.log('get data ok', data);
      //               this.mensaje = data;
      //               const dataTable = data['data'];
      //               this.list = new MatTableDataSource<PeriodicElement>(dataTable);
      //               this.list.paginator = this.paginator;
      //             });
      //         }
      //       });
      //     break;
      //   case 3:
      //     this.maintenancefuelService.deleteElement(json)
      //       .subscribe(dataResponse => {
      //         console.log('get data ok', dataResponse);
      //         if (dataResponse['status'] === 'ERROR') {
      //           this.dialog.open(DialogResponseDialog, {
      //             width: '350px',
      //             data: dataResponse['msg']
      //           }).afterClosed().subscribe(resultClose => {
      //             console.log('ok', resultClose);
      //           });
      //         } else if (dataResponse['status'] === 'OK') {
      //           this.maintenancefuelService.getList()
      //             .subscribe(data => {
      //               console.log('get data ok', data);
      //               if (data['status'] === 'OK') {
      //                 this.mensaje = data;
      //                 const dataTable = data['data'];
      //                 this.msgError = null;
      //                 this.list = new MatTableDataSource<PeriodicElement>(dataTable);
      //                 this.list.paginator = this.paginator;
      //               } else if (data['status'] === 'ERROR') {
      //                 this.msgError = data['msg'];
      //                 this.list = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
      //               }
      //             });
      //         }
      //       });
      //     break;
      // }

    });
  }

  ngOnInit() {
    // variables init
    this.vehicle = '';
    this.km_tope = 0;
    this.fecha = new Date();
    this.msgError = 'Vista habilitada próximamente';
    this.list = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    this.list.paginator = this.paginator;
    // ******************
    // this.maintenancefuelService.getList()
    //   .subscribe(data => {
    //     console.log('get data ok', data);
    //     if (data['status'] === 'OK') {
    //       this.mensaje = data;
    //       const dataTable = data['data'];
    //       this.msgError = null;
    //       this.list = new MatTableDataSource<PeriodicElement>(dataTable);
    //       this.list.paginator = this.paginator;
    //     } else if (data['status'] === 'ERROR') {
    //       this.msgError = data['msg'];
    //     }
    //   });
  }
}


@Component({
  selector: `dialog-new-value-dialog`,
  templateUrl: 'dialog-new-value-dialog.html',
})
export class DialogNewValueDialogTruck {
  constructor(
    public dialogRef: MatDialogRef<DialogNewValueDialogTruck>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: `dialog-response-dialog`,
  templateUrl: 'dialog-response-dialog.html',
})
export class DialogResponseDialogTruck {
  constructor(
    public dialogRef2: MatDialogRef<DialogResponseDialogTruck>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef2.close();
  }
}
