/*  configure your host and api for the backend */

export const localhttp = {
  // api de produccion
  url: "https://apigts.orbitec.pe/api_gts/data/",

  // url: 'http://192.168.31.162/orbitec_back_api_gts/public/data/'

  // api de desarrollo
  // url: "https://apigtsorbitec.test/data/",
};
