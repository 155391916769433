import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor(private rutaActiva: ActivatedRoute) { }
  getRouterLink () {
    let query = { 'enc': '' };
    console.log('query ', query);
    let valueFrom = null;
    console.log('valueFrom ', valueFrom);
    if (localStorage.length > 0) {
      if (localStorage.getItem('enc')) {
        valueFrom = localStorage.getItem('enc');
      }
    }
    console.log('valueFrom ', valueFrom);
    query = this.rutaActiva.queryParams['value'];
    console.log('active route ', this.rutaActiva.queryParams['value']);
    if (query.hasOwnProperty('enc')) {
      let response = query.enc;
      while (response.indexOf('%3D') !== -1) {
        response = response.replace('%3D', '')
      }
      while (response.indexOf('=') !== -1) {
        response = response.replace('=', '')
      }
      console.log('return queryEnc', response);
      return response;
    } else if (valueFrom !== null && valueFrom !== undefined && valueFrom !== '') {
      console.log('localstorage enc param');
      let response = valueFrom;
      while (response.indexOf('%3D') !== -1) {
        response = response.replace('%3D', '');
      }
      while (response.indexOf('=') !== -1) {
        response = response.replace('=', '');
      }
      console.log('return valueForm', response);
      return response;
    } else {
      console.log('no enc param')
      return '';
    }
  }
}
