import {Component, HostListener, Inject, isDevMode, OnInit} from '@angular/core';
import {DriverService} from '../../services/driver.service';
import {localhttp} from '../../../environments/localenvironment';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {GlobalService} from '../../services/global.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material';
import {DialogResponseDialog, PeriodicElement} from '../maintenancefuel/maintenancefuel.component';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})

export class DriverComponent implements OnInit {
  @HostListener('window:scroll')
  newUser: any;
  responseDrivers: any;
  editItem: any;
  activeValues: number;
  licenseAmount: number;
  user: String;
  editFlag: Boolean;
  newDriver: Boolean;
  nameFlag: Boolean;
  dniFlag: Boolean;
  tlfFlag: Boolean;
  empFlag: Boolean;
  dateFlag: Boolean;
  licenseFlag: Boolean;
  plateFlag: Boolean;
  dirFlag: Boolean;
  starsFlag: Boolean;
  stateFlag: Boolean;
  failData: Boolean;
  selectedDriver: any;
  deleteFlag: Boolean;
  ts_start: any;
  vehicleList: any;
  ts_end: any;
  isSearching: Boolean;
  speed: Number;
  srcElement: String;
  srcElementSearch: String;
  dropdownSettings: IDropdownSettings;
  // search
  searchWord: String | null;
  searchResult: any;

  constructor(private httpService: DriverService, private globalService: GlobalService,
              private rutaActiva: ActivatedRoute, private toastr: ToastrService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.speed = 2;
    let enc = null;
    this.isSearching = true;
    this.srcElement = 'assets/lottieFiles/empty.json';
    this.srcElementSearch = 'assets/lottieFiles/route.json';
    if (localStorage.length > 0) {
      enc = localStorage.getItem('enc');
      while (enc.indexOf('%3D') !== -1) {
        enc = enc.slice(0, -3);
      }
      const userData = atob(enc);
      const dataVal = userData.split('|');
      this.user = dataVal[1];
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      searchPlaceholderText: 'Buscar',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Quitar todos',
      itemsShowLimit: 3,
      limitSelection: 1,
      noDataAvailablePlaceholderText: 'Sin placas disponibles',
      allowSearchFilter: true
    };
    this.searchWord = null;
    this.vehicleList = null;
    this.nameFlag = false;
    this.licenseAmount = 0;
    this.licenseFlag = false;
    this.searchResult = [];
    this.dniFlag = false;
    this.tlfFlag = false;
    this.empFlag = false;
    this.dateFlag = false;
    this.plateFlag = false;
    this.dirFlag = false;
    this.starsFlag = false;
    this.newDriver = false;
    this.stateFlag = false;
    this.failData = false;
    this.activeValues = 0;
    this.editItem = {};
    this.editFlag = false;
    this.selectedDriver = null;
    this.deleteFlag = false;
    this.ts_end = new Date();
    this.ts_start = new Date();
    this.newUser = {
      description: 'Jhon Doe',
      driverID: '7179786',
      contactPhone: '999293794',
      empresa: 'Cotrat SAC',
      birthdate: new Date(),
      deviceID: null,
      license: new Date(),
      address: 'Lima - Prueba',
      stars: 1
    };
    this.responseDrivers = null;
    this.getDrivers();
    this.getVehicles();
  }

  showAddDriver() {
    this.newDriver = true;
    this.newUser = {
      description: null,
      driverID: null,
      contactPhone: null,
      empresa: null,
      birthdate: null,
      license: null,
      deviceID: null,
      address: null,
      stars: 1
    };
  }

  showModal() {

    const dialogRef = this.dialog.open(DialogNewValueDialogDriver, {
      width: '480px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('The dialog was closed', result);
        this.httpService.importData(result)
          .subscribe(dataResponse => {
            console.log('get data ok', dataResponse);

            if (dataResponse['status'] === 'ERROR') {
              // -- toster
              this.toastr.error(dataResponse['msg'], 'ERROR',
                {
                  timeOut: 5000,
                  positionClass: 'toast-bottom-right'
                });

            } else if (dataResponse['status'] === 'OK') {
              // -- toster
              this.toastr.success(dataResponse['msg'], 'EXITO',
                {
                  timeOut: 1500,
                  positionClass: 'toast-bottom-right'
                });
              this.getDrivers();
              this.getVehicles();
            }
          });
      }
    });

  }

  getVehicles() {
    this.httpService.getVehicleList()
      .subscribe((res) => {
          console.log(res);
          if (res['status'] === 'OK') {
            this.vehicleList = res['data'].map((obj) => {
              return obj.deviceID;
            });
          } else if (res['status'] === 'ERROR') {
            this.vehicleList = null;
          }
        },
        err => {
          console.log(err);
        });
  }

  hideAddDriver() {
    this.newDriver = false;
    this.nameFlag = false;
    this.dniFlag = false;
    this.tlfFlag = false;
    this.empFlag = false;
    this.dateFlag = false;
    this.plateFlag = false;
    this.dirFlag = false;
    this.starsFlag = false;
    this.licenseFlag = false;
    this.failData = false;
    this.newUser = {
      description: null,
      driverID: null,
      contactPhone: null,
      empresa: null,
      birthdate: null,
      deviceID: null,
      license: null,
      address: null,
      stars: 1
    };
  }

  sendDriver() {
    this.nameFlag = (this.newUser.description === null || this.newUser.description === undefined || this.newUser.description === '');
    this.dniFlag = (this.newUser.driverID === null || this.newUser.driverID === undefined || this.newUser.driverID === '');
    this.plateFlag = (this.newUser.deviceID === null || this.newUser.deviceID === undefined || this.newUser.deviceID === '');
    if (!this.nameFlag && !this.dniFlag) {
      if (this.plateFlag) {
        this.newUser.typeID = 'TOKEN';
      } else if (!this.plateFlag) {
        this.newUser.typeID = 'DNI';
        this.newUser.deviceID = (Array.isArray(this.newUser.deviceID)) ? this.newUser.deviceID['0'] : this.newUser.deviceID;
      }
      this.failData = false;
      this.httpService.saveUser(this.newUser)
        .subscribe(res => {
            console.log('getting response for users: 💁 ', res);
            if (res['status'] === 'ERROR') {
              this.toastr.warning(res['msg'], 'Advertencia',
                {
                  timeOut: 1500,
                  positionClass: 'toast-bottom-right'
                });
            } else if (res['status'] === 'OK') {
              this.searchWord = null;
              this.searchResult = [];
              this.toastr.success(res['msg'], 'Creación Exitosa !!!',
                {
                  timeOut: 1500,
                  positionClass: 'toast-bottom-right'
                });
              this.getDrivers();
              this.getVehicles();
              this.hideAddDriver();
            }
          },
          err => {
            console.log('☢ ERROR: ', err);
          });
    } else {
      this.failData = true;
      this.toastr.warning('Debe completar al menos el Nombre y el Dni/Ident.', 'Advertencia',
        {
          timeOut: 1500,
          positionClass: 'toast-bottom-right'
        });
    }
  }

  toggleActive(value) {
    console.log('isactive');
  }

  searchDrivers() {
    console.log(this.searchWord);
    this.isSearching = true;
    this.speed = 2;
    let searchWord = this.searchWord;

    function filtrarPorID(obj) {
      if ((obj.description.toLowerCase().indexOf(searchWord) !== -1 || obj.deviceID.toLowerCase().indexOf(searchWord) !== -1)) {
        console.log('encontrando coincidencia', obj);
        return obj;
      } else {
        console.log('not found');
      }
    }

    if (searchWord !== null && searchWord !== undefined) {
      this.searchResult = this.responseDrivers.filter(filtrarPorID);
      setTimeout(() => {
        this.isSearching = false;
        this.speed = 1;
      }, 2000);
    } else if (searchWord === '') {
      this.searchResult = this.responseDrivers;
      setTimeout(() => {
        this.isSearching = false;
        this.speed = 1;
      }, 2000);
    }
    console.log('resultado de la busqueda', this.searchResult);
  }

  getDrivers() {
    this.httpService.getList()
      .subscribe(res => {
        if (res['status'] === 'OK') {
          this.responseDrivers = res['data'];
          this.activeValues = 0;
          this.licenseAmount = res['licenseforexp'];
          this.responseDrivers.map((elem) => {
            if (elem.isActive === '1' || elem.isActive === 1) {
              this.activeValues++;
            }
          });
        } else if (res['status'] === 'ERROR') {
          this.toastr.warning(res['msg'], 'Advertencia',
            {
              timeOut: 1500,
              positionClass: 'toast-bottom-right'
            });
        }
        setTimeout(() => {
          this.isSearching = false;
        }, 1500);
      }, err => {
        this.activeValues = 0;
        this.licenseAmount = 0;
        this.responseDrivers = null;
        setTimeout(() => {
          this.isSearching = false;
        }, 1500);
      });
  }

  sendEditDriver() {
    this.nameFlag = (this.editItem.description === null || this.editItem.description === undefined || this.editItem.description === '');
    this.dniFlag = (this.editItem.driverID === null || this.editItem.driverID === undefined || this.editItem.driverID === '');
    this.plateFlag = (this.editItem.deviceID === null || this.editItem.deviceID === undefined || this.editItem.deviceID === '');
    this.stateFlag = (this.editItem.isActive === null || this.editItem.isActive === undefined || this.editItem.isActive === '');
    if (!this.nameFlag && !this.dniFlag && !this.stateFlag) {
      if (this.plateFlag) {
        this.editItem.typeID = 'TOKEN';
      } else if (!this.plateFlag) {
        this.editItem.typeID = 'DNI';
        this.editItem.deviceID = (Array.isArray(this.editItem.deviceID)) ? this.editItem.deviceID['0'] : this.editItem.deviceID;
      }
      this.failData = false;
      this.httpService.saveEditUser(this.editItem)
        .subscribe(res => {
            console.log(res);
            if (res['status'] === 'OK') {
              this.getDrivers();
              this.hideEditDriver();
              this.getVehicles();
              this.searchWord = null;
              this.searchResult = [];
            } else if (res['status'] === 'ERROR') {
              console.log('🔥 warning 🔥');
              this.toastr.warning(res['msg'], 'Advertencia',
                {
                  timeOut: 15000,
                  positionClass: 'toast-bottom-right'
                });
            }
          },
          err => {
            console.log(err);
          });
    } else {
      this.failData = true;
    }
  }

  editDriver(driver) {
    this.editFlag = true;
    let date = null;
    if (driver.birthdate !== null && driver.birthdate !== undefined) {
      const dateVal = driver.birthdate.split('/');
      date = dateVal[2] + '-' + dateVal[1] + '-' + dateVal[0];
    }
    this.editItem = {
      description: driver.description,
      driverID: driver.driverID,
      contactPhone: driver.contactPhone,
      empresa: driver.empresa,
      birthdate: date,
      deviceID: driver.deviceID,
      typeID: driver.typeID,
      license: driver.licenseExpire,
      address: driver.address,
      stars: driver.stars,
      id: driver.id,
      isActive: driver.isActive
    };
    window.scroll({top: 0, left: 0});
  }

  hideEditDriver() {
    this.editFlag = false;
    this.nameFlag = false;
    this.dniFlag = false;
    this.tlfFlag = false;
    this.empFlag = false;
    this.dateFlag = false;
    this.plateFlag = false;
    this.dirFlag = false;
    this.starsFlag = false;
    this.stateFlag = false;
    this.failData = false;
    this.editItem = {
      description: null,
      driverID: null,
      contactPhone: null,
      empresa: null,
      typeID: null,
      birthdate: null,
      deviceID: null,
      address: null,
      license: null,
      stars: 1,
      isActive: null
    };
  }

  deleteDriver(driver) {
    this.selectedDriver = driver.driverID;
    this.deleteFlag = true;
  }

  sendDelete() {
    this.httpService.deleteItem(this.selectedDriver)
      .subscribe((res) => {
          this.httpService.getList()
            .subscribe(res => {
              this.responseDrivers = res['data'];
              this.searchWord = null;
              this.searchResult = [];
              this.getVehicles();
              this.toastr.success(res['msg'], '',
                {
                  timeOut: 1500,
                  positionClass: 'toast-bottom-right'
                });
            }, err => {
              this.toastr.warning('Falló la conexión al servidor', 'Advertencia',
                {
                  timeOut: 1500,
                  positionClass: 'toast-bottom-right'
                });
              this.responseDrivers = 'fallo';
            });
          this.hideDelete();
        },
        err => {
          console.log('service');
        });
  }

  hideDelete() {
    this.selectedDriver = null;
    this.deleteFlag = false;
  }

  getMapLink(lat, long) {
    window.open('https://maps.google.com/?q=' + lat + ',' + long);
  }

  filterDate = (newValue) => {
    const _newValue = newValue;
    const arr = newValue.toString().split('/');
    if (arr.length === 1) {
      const filter = (ele) => {
        let val = null;
        if (ele.length === 1) {
          val = '0' + ele;
        } else {
          val = ele;
        }
        return val;
      };
      const month = (_newValue.getMonth() + 1).toString();
      const day = _newValue.getDate().toString();

      return filter(day) + '/' + filter(month) + '/' + _newValue.getFullYear();
    } else if (arr.length === 3) {
      return newValue;
    }
  };

  getGenerateAct(driver) {
    const enc = this.globalService.getRouterLink();
    let url = 'https://apigts.orbitec.pe/api_gts/data/download_report_data';
    if (isDevMode()) {
      url = localhttp.url + 'download_report_data';
    } else {
      url = 'https://apigts.orbitec.pe/api_gts/data/download_report_data';
    }
    let dateend = this.ts_end;
    let datestart = this.ts_start;
    const dateend2 = dateend.toString().split('-');
    const datestart2 = datestart.toString().split('-');
    const dateend3 = dateend2[2] + '/' + dateend2[1] + '/' + dateend2[0];
    const datestart3 = datestart2[2] + '/' + datestart2[1] + '/' + datestart2[0];
    const link = url + '?id=exceso_velocidad_rutas_chofer&tipo=device&item=' + driver.deviceID +
      '&formato=excel&finicio=' + this.filterDate(datestart3) + ' 00:00:00&ffinal=' + this.filterDate(dateend3) + ' 23:59:59' + '&params=&separator=coma&act=control&usr=cotratsac&enc=' + enc;
    window.location.href = link;
  }
}


export interface DialogData {
  dateInit: Date;
  maxDate: Date;
  dateTime: Date;
  price: number;
  showPrice: boolean;
  showDate: boolean;
  showDelete: boolean;
  title: string;
  command: string;
  enc: string;
  file: File;
}

@Component({
  selector: `dialog-new-value-dialog`,
  templateUrl: 'dialog-new-value-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogNewValueDialogDriver {
  constructor(
    public dialogRef: MatDialogRef<DialogNewValueDialogDriver>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  fileToUpload: File | null = null;

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(files);
    console.log(this.fileToUpload);
    this.data.file = this.fileToUpload;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  downloadTemplate(template) {
    let url = 'https://apigts.orbitec.pe/api_gts/data/download_template?template=' + template;
    if (isDevMode()) {
      console.log('🌈 development!');
      url = localhttp.url + 'download_template?template=' + template;
    }
    window.open(url);
  }
}

@Component({
  selector: `dialog-response-dialog`,
  templateUrl: 'dialog-response-dialog.html',
})
export class DialogResponseDialogDriver {
  constructor(
    public dialogRef2: MatDialogRef<DialogResponseDialogDriver>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef2.close();
  }
}

