import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {localhttp} from '../../environments/localenvironment';
import {GlobalService} from './global.service';

interface DataList {
  obj: Object;
}

@Injectable({
  providedIn: 'root'
})

export class MaintenancefuelService {
  static filterDate = (newValue) => {
    let _newValue = newValue;
    if (newValue.hasOwnProperty('_d')) {
      _newValue = newValue._d;
    }
    const filter = (ele) => {
      let val = null;
      if (ele.length === 1) {
        val = '0' + ele;
      } else {
        val = ele;
      }
      return val;
    };
    const month = (_newValue.getMonth() + 1).toString();
    const day = _newValue.getDate().toString();

    return _newValue.getFullYear() + '/' + filter(month) + '/' + filter(day);
  };

  constructor(private http: HttpClient, private globalService: GlobalService) {
  }

  getList = () => {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.get<DataList>(localhttp.url + 'fuel/fuel_price_control?enc=' + enc,
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.get<DataList>('https://apigts.orbitec.pe/api_gts/data/fuel/fuel_price_control?enc=' + enc,
        {headers: headers, withCredentials: true});
    }
  };
  createElement = (data) => {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    const json = {
      enc,
      'date': MaintenancefuelService.filterDate(data.date),
      'price': data.price
    };
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.post<DataList>(localhttp.url + 'fuel/fuel_price_control', {
          enc,
          'date': MaintenancefuelService.filterDate(data.date),
          'price': data.price
        },
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.post<DataList>('https://apigts.orbitec.pe/api_gts/data/fuel/fuel_price_control', {
        enc,
        'date': MaintenancefuelService.filterDate(data.date),
        'price': data.price
      }, {headers: headers, withCredentials: true});
    }
  };
  editElement = (data) => {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    const json = {
      enc,
      'date': data.date,
      'price': data.price
    };
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.put<DataList>(localhttp.url + 'fuel/fuel_price_control', {
          enc,
          'date': data.date,
          'price': data.price
        },
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production!');
      return this.http.put<DataList>('https://apigts.orbitec.pe/api_gts/data/fuel/fuel_price_control', {
        enc,
        'date': data.date,
        'price': data.price
      }, {headers: headers, withCredentials: true});
    }
  };
  deleteElement = (data) => {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    const json = {
      enc,
      'date': data.date,
      'price': data.price
    };
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development delete!');
      return this.http.delete(localhttp.url + 'fuel/fuel_price_control?json=' + JSON.stringify(json),
        {headers: headers, withCredentials: true});
    } else {
      console.log('💪 Production delete!');
      return this.http.delete('https://apigts.orbitec.pe//api_gts/data/fuel/fuel_price_control?json=' + JSON.stringify(json),
        {headers: headers, withCredentials: true});
    }
  };
}
