import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-lottie',
  templateUrl: './lottie.component.html',
  styleUrls: ['./lottie.component.scss'],
})
export class LottieComponent implements OnInit {
  @Input() srcElement: String;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  constructor() {
    this.lottieConfig = {
      path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/star/star.json',
      // renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
  ngOnInit() {
  }
  handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

}
