import {Component, OnInit, ElementRef, OnDestroy} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTES } from '../sidebar/sidebar.component';
import {AlarmService} from '../../services/alarm.service';
import {ToastrService} from 'ngx-toastr';
import {element} from 'protractor';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  user: any;
  numberAlarm: number;
  volumen: Boolean;
  ts_start: string;
  ts_end: string;
  title = 'ORBITEC - TRACKING';
  srcElement: String;

  constructor(location: Location,  private toastr: ToastrService, private element: ElementRef, private router: Router, private httpService: AlarmService) {
    this.location = location;
  }
  filterDate = (newValue) => {
    const _newValue = newValue;
    const arr = newValue.toString().split('/');
    if (arr.length === 1) {
      const filter = (ele) => {
        let val = null;
        if (ele.length === 1) {
          val = '0' + ele;
        } else {
          val = ele;
        }
        return val;
      };
      const month = (_newValue.getMonth() + 1).toString();
      const day = _newValue.getDate().toString();

      return filter(day) + '/' + filter(month) + '/' + _newValue.getFullYear();
    } else if (arr.length === 3) {
      return newValue;
    }
  }
  ngOnDestroy() {
  }
  ngOnInit() {
    this.srcElement = 'assets/lottieFiles/message.json';
    const noiseFlag = localStorage.getItem('gts_sound');
    if (noiseFlag === 'true') {
      this.volumen = true;
    } else {
      this.volumen = false;
    }
    const ts_end = new Date();
    const ts_start = new Date();
    this.ts_end = this.filterDate(ts_end) + ' 23:59:59';
    this.ts_start = this.filterDate(ts_start) + ' 00:00:00';
    this.numberAlarm = 0;
    this.getList();
    setInterval(() => {
      this.getList();
      this.getTitle();
    }, 120000);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    let enc = localStorage.getItem('enc');
    while (enc.indexOf('%3D') !== -1) {
      enc = enc.slice(0, -3);
    }
    const result = (atob(enc)) || 'user|Usuario Orbitec';
    const array = result.split('|');
    this.user = array[1];
  }
  scroll () {
    console.log('scroll on navbar');
    const offset = $('body').offset();
    console.log('top body', offset);
    $('#navbar').css({ backgroundColor: 'rgba(255,255,255,0.5)' });
  }
  makenoise () {
    let a = new Audio( 'assets/alarm6.mp3');
    a.play();
  }
  getList () {
    this.httpService.getList({ 'ts_start': this.ts_start, 'ts_end': this.ts_end })
      .subscribe(res => {
        if (res['status'] === 'OK') {
          const dataValues = res['data'];
          const memoryValue = localStorage.getItem('gts_numberAlarm');
          let memoryLengthValues = localStorage.getItem('gts_memoryLengthValues');
          this.numberAlarm = 0;
          dataValues.map((elem) => {
            if (elem.atendido === '0') {
              this.numberAlarm++
            }
          });
          if (memoryLengthValues !== undefined && memoryLengthValues !== null &&
            memoryLengthValues !== 'undefined' && memoryLengthValues !== 'null') {
            if (parseFloat(memoryLengthValues) < dataValues.length) {
              localStorage.setItem('gts_memoryLengthValues', dataValues.length);
              if (parseFloat(memoryValue) !== this.numberAlarm) {
                localStorage.setItem('gts_numberAlarm', this.numberAlarm.toString());
                const noise = localStorage.getItem('gts_sound');
                if (noise === 'true' && this.numberAlarm > 0 && this.numberAlarm !== undefined &&
                  this.numberAlarm !== null && memoryValue !== null && memoryValue !== undefined &&
                  memoryValue !== 'null' && memoryValue !== 'undefined') {
                  this.makenoise()
                }
              }
            }
          } else {
            const long = dataValues.length;
            localStorage.setItem('gts_memoryLengthValues', long.toString());
          }
        } else if (res['status'] === 'ERROR') {
          this.numberAlarm = 0;
          localStorage.setItem('gts_numberAlarm', '0');
          console.log('no hay notificaciones pendientes')
        }
      }, err => {
        localStorage.setItem('gts_numberAlarm', '0');
        this.numberAlarm = null;
      });
  }
  getTitle () {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return '';
  }
  toggleVolumen () {
    if (this.volumen) {
      localStorage.setItem('gts_sound', 'false');
      this.volumen = false;
    } else {
      localStorage.setItem('gts_sound', 'true');
      this.volumen = true;
    }
  }
}
