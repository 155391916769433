import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tracking';
  navLinks: any[];
  activeLinkIndex = -1;
  constructor(private router: Router, private rutaActiva: ActivatedRoute) {
  }
  ngOnInit(): void {
    console.log('debug');
    this.navLinks = [
      {
        label: 'Dashboard',
        link: './dashboard',
        index: 0
      },
      {
        label: 'Reportes',
        link: './reportes_especiales',
        index: 1
      }, {
        label: 'Registro de combustible',
        link: './registro_combustible',
        index: 2
      },
      {
        label: 'Reportes programados',
        link: './programacion',
        index: 3
      }
    ];
    this.router.events.subscribe((res) => {
      let str = '';
      if (res.hasOwnProperty('url')) {
        if (res['url'].indexOf('?enc=') !== -1) {
          str = res['url'].split('?enc=');
          str = str[1];
          sessionStorage.setItem('enc', str);
          localStorage.setItem('enc', str);
        }
      }
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }
}
