import { NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import {AdminLayoutComponent} from './layout/admin-layout/admin-layout.component';
import {WhiteLayoutComponent} from './layout/white-layout/white-layout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layout/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  },
  {
    path: '',
    component: WhiteLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layout/white-layout/white-layout.module#WhiteLayoutModule'
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
