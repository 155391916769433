import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import { LottieAnimationViewModule } from 'ng-lottie';
import { LottieComponent } from './lottie/lottie.component';
import { defineCustomElements } from '@teamhive/lottie-player/loader';

defineCustomElements(window);
@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    LottieComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatIconModule,
    LottieAnimationViewModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    SidebarComponent,
    NavbarComponent
  ]
})
export class ComponentsModule { }
