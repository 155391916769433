import { Injectable, isDevMode } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {localhttp} from '../../environments/localenvironment';
import {GlobalService} from './global.service';

interface DataList {
  obj: Object;
}

@Injectable({
  providedIn: 'root'
})
export class AlarmService {

  constructor(private http: HttpClient, private globalService: GlobalService) { }
  getList (data) {
    const enc = this.globalService.getRouterLink();
    let headers = new HttpHeaders();
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.get<DataList>(localhttp.url + 'notificaciones/notificaciones?enc=' + enc + '&ts_end=' + data.ts_end + '&ts_start=' + data.ts_start,
        { headers: headers,  withCredentials: true });
    } else {
      console.log('💪 Production!');
      return this.http.get<DataList>('https://apigts.orbitec.pe/api_gts/data/notificaciones/notificaciones?enc=' + enc + '&ts_end=' + data.ts_end + '&ts_start=' + data.ts_start,
        { headers: headers,  withCredentials: true });
    }
  }
  readItems (data) {
    const enc = this.globalService.getRouterLink();
    const accountID1 = (atob(enc));
    const accountID2 = accountID1.toString();
    const accountID3 = accountID2.toString().split('|');
    const accountID4 = accountID3[0];
    let headers = new HttpHeaders();
    // headers.set('X-Requested-With', 'XMLHttpRequest');
    headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    const dataValues = data.map((elem) => {
      let retObj = {
        accountID: 'null',
        deviceID: null,
        messageID: null
      };
      retObj.accountID = accountID4;
      retObj.deviceID = elem.deviceID;
      retObj.messageID = elem.messageID;
      return retObj
    });
    if (isDevMode()) {
      console.log('🌈 development!');
      return this.http.put( localhttp.url + 'notificaciones/notificaciones', JSON.stringify({'noty': dataValues}),
        { headers: headers, withCredentials: true });
    } else {
      console.log('💪 Production!');
      return this.http.put('https://apigts.orbitec.pe/api_gts/data/notificaciones/notificaciones', JSON.stringify({'noty': dataValues}),
        {  headers: headers, withCredentials: true });
    }
  }
}
